


























import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "ContextMenu",
  props: {
    menuConfigs: Array,
    // menuConfigs: [{ label: "...", onClick: () => {} }, ...]
  },
  setup() {
    const ICONS = {
      Bantuan: "question-circle",
      Hapus: "trash",
      Keluar: "sign-out-alt",
      "Tambah Residen": "plus",
      "Tambah Konsulen": "plus",
      Ubah: "edit",
      "Ubah Sandi": "edit",
      Verifikasi: "check",
    };
    return {
      ICONS,
    };
  },
});
